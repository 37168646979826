import React from 'react'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

import TitleHeader from '../components/titleheader'
import Card from '../components/card'
import corpresponsibilityimage1 from '../assets/images/esg-lp/esg_environment_box_d.png'
import corpresponsibilityimage2 from '../assets/images/esg-lp/esg_social_box_d.png'
import corpresponsibilityimage3 from '../assets/images/esg-lp/esg_governance_box_d.png'

import esgPdf2023 from '../assets/images/TCP-2022-ESG-Report-Final.pdf'
import esgPdf2022 from '../assets/images/esgReport2021.pdf'
import esgPdf2021 from '../assets/images/esgReport2020.pdf'
import esgPdf from '../assets/images/2019_ESG_Annual_Progress_Report.pdf'
// import esgPdfTwo from '../assets/images/2021_APRIL_ESG_UPDATE.pdf'

import internalesgdk from '../assets/images/esg_2021/esg_lp_header_dk.jpg'
import internalesgmb from '../assets/images/esg_2021/esg_lp_header_mobile.jpg'

import pressdk from '../assets/images/esg_2021/dk-press.png'
import pressmb from '../assets/images/esg_2021/mb-press.png'

import { trackPageData, getPageDetails } from '../analytics'

class CorporateResponsibility extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO title="" />
        <div
          style={{
            position: `relative`,
          }}
          class="row"
        >
          <a href={`${esgPdf2023}`} target="_blank">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <TitleHeader
                image={internalesgdk}
                mbheaderimage={internalesgmb}
                title="ENVIRONMENT, SOCIAL &amp; GOVERNANCE"
                addClass="corporateTitle esgTitle"
              />
            </div>
          </a>
          <br />

          <a
            class="esg-postion-lb"
            href={`https://newsplace.childrensplace.com/the-childrens-place-issues-2022-annual-esg-report/`}
            target="_blank"
          >
            <div class="col-12">
              <img src={pressdk} className="img-fluid hidden-xs" alt="" />
              <img src={pressmb} className="img-fluid visible-xs" alt="" />
            </div>
          </a>
        </div>

        <div class="row mb-4 mt-1">
          <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            <div>
              <p>
                At the Children’s Place, ‘family’ is at the center of all that
                we do. As a company focused on children‘s product, we believe it
                is important that our business contribute to a healthy planet
                and equitable society for the benefit of future generations.
                Having a positive impact begins by supporting Environment,
                Social and Governance (ESG) practices so we are committed to the
                following goals:
              </p>
              <div class="row justify-content-center">
                <div class="col-md-7 col-sm-12 col-xs-12">
                  <ul class="list-inline list-bullet text-left">
                    <li class="corp-inside">
                      <b>ENVIRONMENT</b>: We seek to positively impact the
                      communities and environments affected by our business.
                    </li>
                    <li class="corp-inside">
                      <b>SOCIAL</b>: We invest in our associates, support the
                      people who make our product and give back through
                      philanthropic activities.
                    </li>
                    <li class="corp-inside">
                      <b>GOVERNANCE:</b> We maintain a governance framework that
                      aligns with market best practices and shareholder
                      expectations
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-4 col-sm-12">
            <Link to="/environment">
              <Card
                image={corpresponsibilityimage1}
                title="ENVIRONMENT"
                description=""
              ></Card>
            </Link>
          </div>

          <div class="col-md-4 col-sm-12">
            <Link to="/social">
              <Card
                image={corpresponsibilityimage2}
                title="SOCIAL"
                description=""
              ></Card>
            </Link>
          </div>

          <div class="row d-md-none mb-5" style={{ width: 100 }}></div>
          <div class="col-md-4 col-sm-12">
            <Link to="/governance">
              <Card
                image={corpresponsibilityimage3}
                title="GOVERNANCE"
                description=""
              ></Card>
            </Link>
          </div>
        </div>

        <div class="row justify-content-center">
          <p class="text-center">
            Learn more about our ESG strategy and progress to date. Our 2022 ESG
            Report has been prepared with reference to the Global Reporting
            Initiative (GRI) Standards and in alignment with material
            Sustainable Accounting Standards Board (SASB) Standards.
          </p>
        </div>
        <div class="row mb-5 justify-content-center" id="force-display">
          <p>
            <a target="_blank" href={`${esgPdf2023}`}>
              2022 ESG Report{' '}
            </a>
            |{' '}
            <a
              target="_blank"
              href={`https://newsplace.childrensplace.com/the-childrens-place-issues-2022-annual-esg-report/`}
            >
              Press Release
            </a>
          </p>
          <p>
            <a target="_blank" href={`${esgPdf2022}`}>
              2021 ESG Report
            </a>{' '}
            |{' '}
            <a
              target="_blank"
              href={`https://newsplace.childrensplace.com/the-childrens-place-issues-2021-annual-esg-report/`}
            >
              Press Release
            </a>
          </p>

          <p>
            <a target="_blank" href={`${esgPdf2021}`}>
              2020 ESG Report
            </a>{' '}
            |{' '}
            <a
              target="_blank"
              href={`https://newsplace.childrensplace.com/the-childrens-place-issues-2020-annual-esg-report/`}
            >
              Press Release
            </a>
          </p>

          <a target="_blank" href={`${esgPdf}`}>
            <p>2019 ESG Report</p>
          </a>
        </div>
      </Layout>
    )
  }
}

export default CorporateResponsibility
