
import React from 'react'
import PropTypes from 'prop-types'


const Card = ({ children, image, title, description, classname }) => (

    <div class={"card "+ (classname ? classname : '')}>
        <div class="row no-gutters text-center">
            {image &&<div class="col-lg-6 col-md-12 fill">
                <img src={image} class="card-img" alt="..." />
            </div>}
            <div className={"col-md-12 card-center " + (image ? 'col-lg-6' : '')}>
                <div class="card-body">
                    <h4 class="card-title">{title}</h4>
                    <p class="card-text">{description}</p>
                    {children}
                </div>
            </div>
        </div>
    </div>
)

Card.propTypes = {
    title: PropTypes.node.isRequired,
    image: PropTypes.node,
    description: PropTypes.string,
    children: PropTypes.node,
    classname: PropTypes.string,
}


export default Card
