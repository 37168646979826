import React from 'react'
import PropTypes from 'prop-types'

import Slider from '../components/slider'
import SliderItem from '../components/slideritem'

const TitleHeader = ({ title, image, mbheaderimage, slidertrue, slider1, slider2, slider3, mbslider1, mbslider2, mbslider3, addClass }) => (
    <div>
        {slidertrue && <div className="row mb-3">
            <div className="col-12 d-none d-md-block">
                <Slider id="careers-corporate-slider" classname="test no-controls" datainterval="3000">
                    <SliderItem active={true}><img src={slider1} className="img-fluid" alt="" /></SliderItem>
                    <SliderItem><img src={slider2} className="img-fluid" alt="" /></SliderItem>
                    {slider3 &&<SliderItem><img src={slider3} className="img-fluid" alt="" /></SliderItem>}
                </Slider>
            </div>
            <div className="col-12 d-md-none">
                <Slider id="careers-corporate-slider" classname="test no-controls" datainterval="3000">
                    <SliderItem active={true}><img src={mbslider1} className="img-fluid" alt="" /></SliderItem>
                    <SliderItem><img src={mbslider2} className="img-fluid" alt="" /></SliderItem>
                    {mbslider3 &&<SliderItem><img src={mbslider3} className="img-fluid" alt="" /></SliderItem>}
                </Slider>
            </div>
        </div>}
        {!slidertrue && <div className="row mb-3">
            <div className="col-12" style={{marginTop:"40px"}}>
                <img src={image} className="img-fluid d-none d-md-inline-block" alt="" />
                <img src={mbheaderimage} style={{width:'100%'}} className="img-fluid d-md-none" alt="" />
            </div>
        </div>}
        <div className="title-header text-center">
            <h1 className={addClass}>
            {title}
            </h1>
            <hr />
        </div>
    </div>
)

TitleHeader.propTypes = {
    title: PropTypes.node.isRequired,
    image: PropTypes.node,
    mbheaderimage: PropTypes.node,
    slidertrue: PropTypes.string,
    slider1: PropTypes.node,
    slider2: PropTypes.node,
    slider3: PropTypes.node,
    mbslider1: PropTypes.node,
    mbslider2: PropTypes.node,
    mbslider3: PropTypes.node,
    datainterval: PropTypes.string,
    addClass: PropTypes.string, 
}

export default TitleHeader
