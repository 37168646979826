import React from 'react'
import PropTypes from 'prop-types'


const Slider = ({ children, id, classname, datainterval}) => (
    <div id={id} className={(classname ? classname : '') + ' carousel slide'} data-pause="false" data-ride="carousel" data-interval={datainterval} style={{overflow:'hidden'}}>
            <div class="carousel-inner">{children}</div>
        <a class="carousel-control-prev" href={'#'+id} role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href={'#'+id} role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
)

Slider.propTypes = {
    id: PropTypes.string.isRequired,
    classname: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default Slider
