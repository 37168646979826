import React from 'react'
import PropTypes from 'prop-types'


const SliderItem = ({ children, active, classname }) => (
    <div className={(classname ? classname : '') + (active ? ' active' : '') + " carousel-item " }>
        {children}
    </div>
)

SliderItem.propTypes = {
    active: PropTypes.bool,
    classname: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default SliderItem
